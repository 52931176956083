<template>
  <div class="clip-bot-container clip-bottom" :style="cssVars({ clipBottom, clipBottomMobile })" />
</template>

<script>
import { cssVars } from '@/utils/css';

export default {
  props: {
    clipBottom: { type: String },
    clipBottomMobile: { type: String },
  },
  setup() {
    return { cssVars };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.clip-bottom {
  clip-path: var(--clip-bottom-mobile, var(--clip-bottom));
  height: 100%;

  @screen md {
    clip-path: var(--clip-bottom);
  }
}

.clip-bot-container {
  position: absolute;
  width: 100%;
  @include responsive('height', px(100), px(300));
  @include responsive('top', calc(100% + #{px(100)}), calc(100% + #{px(300)}));
  transform: translateY(-100%);
}
</style>
