<template>
  <section class="text-kreislink part relative" :class="[payload.hintergrundfarbe]">
    <clip
      class="clipper"
      :clip-top="payload.clip"
      :clip-top-mobile="payload.clipmobile"
      :class="[payload.clipcolor, payload.includetop.length > 0 ? 'include-top': '']"
    />
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-4 order-2 md:offset-1 z-10">
          <div class="flex justify-end">
            <div class="pr-20">
              <circle-link
                v-if="payload.link.length"
                :to="payload.link[0].link"
              >
                <div class="flex flex-col h-full items-center justify-center text-center text-m px-4 text-gray">
                  <div class="mb-4" v-html="payload.linktext" />
                  <div>
                    <Arrow class="arrow" />
                  </div>
                </div>
              </circle-link>
            </div>
          </div>
        </div>
        <div class="text-container  order-1 mt-3 md:mt-0 md:order-3 col-12 md:col-6">
          <section class="mb-4" v-html="payload.text" />
          <section v-if="payload.kontaktbutton.length > 0">
            <a href="mailto:hello@ginevracobberdog.ch" class="btn btn-contact">
              Kontakt aufnehmen
            </a>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import Container from '../utils/Container.vue';
import TextClipBottom from '../utils/TextClipBottom.vue';
import { useWithinBreakpoint } from '@/composables/breakpoints';
import CircleLink from '../utils/CircleLink.vue';
import Arrow from '@/assets/images/arrow-right.svg';

export default {
  components: {
    Container, TextClipBottom, CircleLink, Arrow,
  },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const isDesktop = useWithinBreakpoint('md');
    return {
      isLeft: computed(() => props.payload.bildposition.includes('left')),
      isDesktop,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.text-kreislink {
  position: relative;
  z-index: 10;

  @include responsive('padding-top', px(40), px(137));
  @include responsive('padding-bottom', px(40), px(95));

  &.orange {
    @apply bg-primary;
  }

  &.blau {
    @apply bg-secondary;
  }

  &:first-child {
    @include responsive(
      'padding-top',
      calc(var(--header-height) + #{px(24)}),
      px(137)
    );
  }

  .constrain {
    position: relative;
    z-index: 100;
  }
}

.text-container {
  z-index: 2;
  :deep(ul) {
    list-style: disc;
    margin-left: px(10);
    color: var(--color-gray-blue);
    margin-bottom: 1rem;
    padding-left: px(10);
  }
}

.arrow {
  height: 40px;
  :deep {
    path {
      fill: var(--color-very-dark-gray);
    }
  }
}

.btn-contact {
  background-color: var(--color-dark-orange);
}

.orange {
  @apply bg-primary;
}

.blau {
  @apply bg-secondary;
}

.clipper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

}

.include-top {
  top: -30px;
}
</style>
