<template>
  <Go class="link" :to="to">
    <div class="circle bg-primary" :class="{ 'bg-secondary': blue }">
      <slot />
    </div>
  </Go>
</template>

<script>
export default {
  props: {
    to: { type: String },
    blue: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/_mixin.scss';

.circle {
  height: px(190);
  width: px(190);
  border-radius: 50%;
}

.link {
  .circle {
     transition: 0.15s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}
</style>
